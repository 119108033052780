<!--
File: RoleEditForm.vue
Description: form for adding/editing a single user role.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('role_key')">
          <label for="role_key">{{ $t('stdCols.name') }}</label>
          <md-input id="role_key" v-model="role_key" type="text" data-vv-name="role_key" required
            v-validate="modelValidations.role_key"></md-input>
        </md-field>

        <md-field :class="getClass('description_en')">
          <label for="description_en">{{ $t('stdCols.description') }} ({{ ($t('translate.en')) }})</label>
          <md-input id="description_en" v-model="description_en" type="text" data-vv-name="description_en" required
            v-validate="modelValidations.description_en"></md-input>
        </md-field>

        <md-field :class="getClass('description_ru')">
          <label for="description_ru">{{ $t('stdCols.description') }} ({{ ($t('translate.ru')) }})</label>
          <md-input id="description_ru" v-model="description_ru" type="text" data-vv-name="description_ru" required
            v-validate="modelValidations.description_ru"></md-input>
        </md-field>

        <md-field :class="getClass('description_kg')">
          <label for="description_kg">{{ $t('stdCols.description') }} ({{ ($t('translate.kg')) }})</label>
          <md-input id="description_kg" v-model="description_kg" type="text" data-vv-name="description_kg" required
            v-validate="modelValidations.description_kg"></md-input>
        </md-field>

        <md-field style="width: 50px; left: 15px">
          <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
        </md-field>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal } from '@/pages/Components'
import {savedMessage} from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'role-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'RoleEditForm',
      eligible: false,

      role_key: null,
      description_en: null,
      description_ru: null,
      description_kg: null,
      active: null,
      isLoading: false,

      initialFormState: null,
      modelValidations: {
        role_key: { required: true, min: 1, max: 100 },
        description_en: { required: true, min: 1, max: 255 },
        description_ru: { required: true, min: 1, max: 255 },
        description_kg: { required: true, min: 1, max: 255 }
      }
    }
  },

  props: {
    roleId: null,
  },

  components: {
    Modal,
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.$emit('close')
      return
    };

    if (this.roleId) {
      const theRole = await this.loadItem(this.roleId)

      this.role_key = theRole.role_key
      this.description_en = theRole.role_description_en
      this.description_ru = theRole.role_description_ru
      this.description_kg = theRole.role_description_kg
      this.active = Boolean(theRole.active)
    }
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
  },

  methods: {
    ...mapActions({
      loadItem: 'ReferenceData/LOAD_ROLE_BY_ID',
      addItem: 'ReferenceData/ADD_NEW_ROLE',
      editItem: 'ReferenceData/UPDATE_ROLE',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      this.isLoading = true
      try {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theItem = this.getCurrentState;

        let errDesc = '';
        let newRoleId;
        try {
          const action = !this.roleId ? this.addItem : this.editItem;
          const payload = !this.roleId ? theItem : { id: this.roleId, theItem };
          const res = await action(payload)
          newRoleId = res?.role_id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        await savedMessage(errDesc, this.$t('users.role'), this.role_key);
        this.resetForm()
        this.$emit('close')
        this.highlightRow(newRoleId)
      } finally {
        this.isLoading = false
      }
    },

    resetForm() {
      this.role_key = null
      this.description_en = null
      this.description_ru = null
      this.description_kg = null
      this.active = null
      this.$validator.reset()
    },
  },

  computed: {
    screenTitle() {
      return this.roleId ? this.$t('screen_titles.role_upd') : this.$t('screen_titles.role_add')
    },

    getCurrentState() {
      return {
        role_key: this.role_key,  //.trim(),
        role_description_en: this.description_en, // ? this.description_en.trim() : null,
        role_description_ru: this.description_ru, // ? this.description_ru.trim() : null,
        role_description_kg: this.description_kg, // ? this.description_kg.trim() : null,
        active: Number(this.active)
      }
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      return this.role_key !== this.initialFormState.role_key ||
        this.description_en !== this.initialFormState.description_en ||
        this.description_ru !== this.initialFormState.description_ru ||
        this.description_kg !== this.initialFormState.description_kg ||
        Number(this.active) !== this.initialFormState.active
    }
  }
}
</script>