<!--
File: Roles.vue
Description: show list of roles entered in the DB.
-->
<template>
  <md-card v-if="eligible">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.search_again')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.role_id === highlightedRow }"
          :id="item.role_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="role_key">
            {{ item.role_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="description">
            {{ item.role_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.active')" md-sort-by="active">
            {{ item.active ? $t('buttons.yes') : $t('buttons.no') }}
          </md-table-cell>
          <md-table-cell :md-label="$t('rbac.permissions_count')" md-sort-by="permission_count" md-numeric>
            <a v-if="item.permission_count > 0" href='#' @click.stop.prevent='showPermissions(item.role_key)'>
              {{ item.permission_count }}</a>
            <span v-else>0</span>
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button v-if="isBtnAllowed('EditButton')" class="md-just-icon md-default md-simple"
                :title="$t('buttons.edit')" @click.native="viewItem(item.role_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
              :title="$t('buttons.delete')" @click.stop.prevent="deleteItem(item.role_id, item.role_key)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
    <EditForm v-if="showDetailsDlg" :roleId="roleId" @close="showDetailsDlg = false" />
  </md-card>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { Pagination } from '@/components'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import messages from '@/mixins/messagesMixin'
  import Fuse from 'fuse.js'
  import EditForm from './RoleEditForm.vue'

  export default {
    name: 'roles-list',
    mixins: [permissions, listView, messages],

    data() {
      return {
        formName: 'Roles',
        eligible: false,
        showSpinner: false,
        showDetailsDlg: false,
        roleId: null,

        currentSort: 'role_key',
        currentSortOrder: 'asc',
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['role_key', 'role_description'],
        fuseSearch: null,
      }
    },

    components: {
      Pagination,
      EditForm
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.onClose()
        return
      };

      this.showSpinner = true
      const history = await this.loadHistory()
      if (history.form === 'roles' && history.use) {
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.$nextTick(() => {
          this.searchQuery = history.data.searchQuery
          this.pagination.currentPage = history.data.currentPage
        })
        this.clearHistory()
      }
      await this.reloadData()
    },

    methods: {
      ...mapActions({
        loadRoles: 'ReferenceData/LOAD_ROLE_LIST',
        //loadUsers: 'LOAD_USERS_LIST',
        delete: 'ReferenceData/DELETE_ROLE',
      }),

      async reloadData() {
        this.showSpinner = true
        await this.loadRoles({})
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
        this.showSpinner = false
     },

      showPermissions(role) {
        const hist = {
          form: 'permissions',
          use: true,
          data: {
            selectedRole: role,
            perPage: 10,
            currentPage: 1
          }
        }
        this.saveHistory(hist)
        this.$router.push('/permissions/permissions')
      },

      viewItem(roleId) {
        this.roleId = roleId
        this.showDetailsDlg = true
      },

      save_history() {
        const hist = {
          form: 'roles',
          data: {
            searchQuery: this.searchQuery,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage,
          }
        }
        this.saveHistory(hist)
      },
    },

    computed: {
      ...mapState({
        tableData: (state) => state.ReferenceData.role_list,
        highlightedRow: (state) => state.History.row
     }),

      ...mapGetters([]),

      rolesCount() {
        return this.tableData.length
      },

    },


    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  outline: 0;
  width: 150px;
  border-right: 0;
  border-bottom: 0;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>